.about-us-bg-gradient {
    background: linear-gradient(to right, #186355, #34D8BA, #186355);
    color: #fff;
    background-size: 200% auto;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
        background-position: right center;
    }
}

.icon-with-text-02 {
    &.about-us-icon-with-text {
        > div {
            position: relative;
            &::after {
                content: "";
                transition-duration: 0.4s;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                opacity: 0;
                background-image: linear-gradient(to right top, #186355,  #34D8BA, #186355);
            }
            i {
                @apply bg-gradient-to-r from-[#186355] via-[#34D8BA] to-[#186355];
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
                i {
                    @apply bg-gradient-to-r from-[#fff] via-[#fff] to-[#fff];
                }
                .feature-box-content {
                    span,
                    p {
                        color: white;
                    }
                }
            }
        }
    }
}

.team-about-us {
    .title-bottom {
        .title {
            color: #232323 !important;
        }
    }
}
